import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto hash`}</strong>{` -- generate and check hashes of files and directories`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto hash <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto hash`}</strong>{` command group provides facilities for generating and
checking hashes of files and directories.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`SHA-256 digest and compare of a file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto hash digest foo.crt
1d14bfeab8532f0fca6220f6a870d069496798e92520c4437e13b9921a3cb7f3  foo.crt

$ step crypto hash compare 1d14bfeab8532f0fca6220f6a870d069496798e92520c4437e13b9921a3cb7f3 foo.crt
ok
`}</code></pre>
    <p>{`SHA-1 digest and compare of a directory:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto hash digest --alg sha1 config/
d419284e29382983683c294f9593183f7e00961b  config/

$ step crypto hash compare --alg sha1 d419284e29382983683c294f9593183f7e00961b config
ok
`}</code></pre>
    <p>{`MD5 of a file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto hash digest --alg md5 --insecure foo.crt
a2c5dae8eae7d116019f0478e8b0a35a  foo.crt
`}</code></pre>
    <p>{`SHA-512/256 of a list of files:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ find . -type f | xargs step crypto hash digest --alg sha512-256
`}</code></pre>
    <p>{`Compare a previously created checksum file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ find path -type f | xargs step crypto hash digest --alg sha512-256 > checksums.txt

$ cat checksums.txt | xargs -n 2 step crypto hash compare --alg sha512-256
`}</code></pre>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "digest/"
              }}>{`digest`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`generate a hash digest of a file or directory`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "compare/"
              }}>{`compare`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`verify the hash digest for a file or directory matches an expected value`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      